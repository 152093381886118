import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { otherRoutes } from "routes/routes-list";
import Loading from "../../components/Loading";

const InfoPage = React.lazy(() => import("./pages/InfoPage"));
const PublicationsPage = React.lazy(() => import("./pages/PublicationsPage"));
const ResearchPage = React.lazy(() => import("./pages/ResearchPage"));
const ContactPage = React.lazy(() => import("./pages/ContactPage"));

const Main = () => (
  <Suspense fallback={<Loading />}>
    <Switch>
      <Route path={otherRoutes.info} render={props => <InfoPage {...props} />} />
      <Route path={otherRoutes.publications} render={props => <PublicationsPage {...props} />} />
      <Route path={otherRoutes.research} render={props => <ResearchPage {...props} />} />
      <Route path={otherRoutes.contact} render={props => <ContactPage {...props} />} />
      <Redirect to={otherRoutes.info} />
    </Switch>
  </Suspense>
);

export default Main;
